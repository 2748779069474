.hamburgerMenu {
    position: absolute;
    background-color: rgb(255, 255, 255);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.5rem
}

.hamburgerMenuItem {
    font-weight: 600;
    font-size: 2rem;
    color: var(--dark-indigio);
}

.closeHamburgerMenuButton {
    position: absolute;
    top: 1.1rem;
    right: 1.1rem;
}