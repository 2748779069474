* {
  margin: 0;
  padding: 0;
}

:root {
  --borders: #e6e7ea;
  --borders-dark: #cfd0d5;
  --white: white;
  --dark-purple-5: #f3f3f4;
  --dark-purple-5-opacity: rgba(13, 22, 47, .05);
  --dark-indigio: #3b32f9;
  --indigo: #6056ff;
  --dark-purple-10-opacity: rgba(13,22,47,.1);
  --indigo-40-opacity: rgba(96,86,255,.4);
  --indigo-25-opacity: rgba(96,86,255,.25);
  --dark-indigo-50-opacity: rgba(59,50,249,.5);
  --white-20-opacity: rgba(255,255,255,.2);
  --purple: #943bff;
  --black-80-opacity: rgba(10, 10, 10, 0.8);
  --grey: #8d8d8d;
  --light-purple-50-opacity: #ced7ff8e;
}